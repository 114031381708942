import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { constants } from 'app/config/constants';


@Injectable()
export class ReportsService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  public getCompletedReports(startDate: string, endDate: string) {
    const queryReports = {
      query: 'CALL getCompletedReports(?, ?)',
      params: [startDate, endDate]
    };

    return this.http.post<any>(constants.url + 'dynamic', queryReports, this.httpOptions);
  }

  public getCompletedReportsByUser(idUser: number, startDate: string, endDate: string) {
    const queryReports = {
      query: 'Call getCompletedReportsByUser(?, ?, ?)',
      params: [idUser, startDate, endDate]
    }

    return this.http.post<any>(constants.url + 'dynamic', queryReports, this.httpOptions);
  }

  public getPendingReports() {
    const queryReports = {
      query: 'Call getPendingReports',
      params: []
    };

    return this.http.post<any>(constants.url + 'dynamic', queryReports, this.httpOptions);
  }

  public getPendingReportsByUser(idUser: number) {
    const queryReports = {
      query: 'Call getPendingReportsByUser(?)',
      params: [idUser]
    }

    return this.http.post<any>(constants.url + 'dynamic', queryReports, this.httpOptions);
  }

  public getReportById(idReport: number) {
    const data = {
      query: 'CALL getReportById(?)',
      params: [idReport]
    };

    return this.http.post<any>(constants.url + 'dynamic', data, this.httpOptions);
  }
}

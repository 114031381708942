import { environment } from 'environments/environment';

export class constants {
    // General URL
    public static url: string = environment.url;
    // Microservices URLs
    public static MS1: string = constants.url + 'MS1V1/';
    public static MS2: string = constants.url + 'MS2V1/';//constants.url + 'MS2/';
    // Logos paths
    public static LOGOS_DIR: string = 'app/assets/img/logo/';
    public static LOGO1_100x34: string = constants.LOGOS_DIR + 'suplidora_logo_100x34.png';
    public static LOGO1_200x67: string = constants.LOGOS_DIR + 'suplidora_logo_200x67.png';
    public static LOGO1_626x211: string = constants.LOGOS_DIR + 'suplidora_logo_626x211.png';
    public static LOGO2_100x34: string = constants.LOGOS_DIR + 'suplidora_logo_2_100x34.png';
    public static LOGO2_200x67: string = constants.LOGOS_DIR + 'suplidora_logo_2_200x67.png';
    public static LOGO2_626x211: string = constants.LOGOS_DIR + 'suplidora_logo_2_626x211.png';
    public static LOGO_HD: string = constants.LOGOS_DIR + 'suplidora_logo_hd.png';
    public static LOGO_2021: string = constants.LOGOS_DIR + 'suplidora_logo_hd.png';
    public static LOGO_VILLA: string = constants.LOGOS_DIR + 'Villa.png';
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ChildrenItems } from '../../../../../WebView/src/app/sidebar/sidebar.component';
import { AuthService } from 'app/services/auth/auth.service';


export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    children?: ChildrenItems[];
}


export const ROUTES_Admin: RouteInfo[] = [
    {
        path: '/admin/profile',
        title: 'Mi Perfil',
        type: 'link',
        icontype: 'nc-icon nc-circle-10'
    },
    {
        path: '/admin/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'nc-icon nc-bank'
    },
    {
        path: '/admin/clients',
        title: 'Clientes',
        type: 'sub',
        icontype: 'nc-icon nc-single-02',
        children: [
            {
                path: 'add',
                title: 'Añadir Cliente',
                ab: 'A'
            },
            {
                path: 'search',
                title: 'Buscar Cliente',
                ab: 'B'
            },
        ]
    },
    {
        path: '/admin/contracts',
        title: 'Contratos',
        type: 'sub',
        icontype: 'nc-icon nc-paper',
        children: [
            {
                path: 'add',
                title: 'Añadir Contrato',
                ab: 'A'
            },
            {
                path: 'search',
                title: 'Buscar Contratos',
                ab: 'B'
            },
        ]
    },
    {
        path: '/admin/tickets',
        title: 'Boletas',
        type: 'sub',
        icontype: 'nc-icon nc-tag-content',
        children: [
            {
                path: 'add',
                title: 'Añadir Boleta',
                ab: 'A'
            },
            {
                path: 'search',
                title: 'Buscar Boletas',
                ab: 'B'
            },
            {
                path: 'searchRef',
                title: 'Boletas con Refacciones',
                ab: 'R'
            },
            {
                path: 'bitacora',
                title: 'Bitacoras',
                ab: 'B'
            },
        ]
    },
    {
        path: '/admin/reports',
        title: 'Reportes',
        type: 'sub',
        icontype: 'nc-icon nc-single-copy-04',
        children: [
            {
                path: 'history',
                title: 'General',
                ab: 'G'
            },
        ]
    },
    {
        path: '/admin/inventario',
        title: 'Inventario',
        type: 'sub',
        icontype: 'nc-icon nc-laptop',
        children: [
            {
                path: 'series',
                title: 'Series',
                ab: 'S'
            },
            {
                path: 'modelos',
                title: 'Modelos',
                ab: 'M'
            },
            {
                path: 'marcas',
                title: 'Marcas',
                ab: 'M'
            },
        ]
    },
    {
        path: '/admin/technicals',
        title: 'Técnicos',
        type: 'sub',
        icontype: 'nc-icon nc-badge',
        children: [
            {
                path: 'add',
                title: 'Añadir Técnico',
                ab: 'A'
            },
            {
                path: 'search',
                title: 'Buscar Técnicos',
                ab: 'B'
            },
        ]
    },
    {
        path: '/admin/hardware',
        title: 'Refacciones',
        type: 'sub',
        icontype: 'nc-icon nc-settings',
        children: [
            {
                path: 'search',
                title: 'Buscar Refacciones',
                ab: 'B'
            },
        ]
    },
    {
        path: '/admin/rastreo',
        title: 'Seguimiento',
        type: 'link',
        icontype: 'nc-icon nc-globe',
    }
];



export const ROUTES_SuperAdmin: RouteInfo[] = [
    {
        path: '/admin/profile',
        title: 'Mi Perfil',
        type: 'link',
        icontype: 'nc-icon nc-circle-10'
    },
    {
        path: '/admin/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'nc-icon nc-bank'
    },
    {
        path: '/admin/clients',
        title: 'Clientes',
        type: 'sub',
        icontype: 'nc-icon nc-single-02',
        children: [
            {
                path: 'add',
                title: 'Añadir Cliente',
                ab: 'A'
            },
            {
                path: 'search',
                title: 'Buscar Cliente',
                ab: 'B'
            },
        ]
    },
    {
        path: '/admin/contracts',
        title: 'Contratos',
        type: 'sub',
        icontype: 'nc-icon nc-paper',
        children: [
            {
                path: 'add',
                title: 'Añadir Contrato',
                ab: 'A'
            },
            {
                path: 'search',
                title: 'Buscar Contratos',
                ab: 'B'
            },
        ]
    },
    {
        path: '/admin/tickets',
        title: 'Boletas',
        type: 'sub',
        icontype: 'nc-icon nc-tag-content',
        children: [
            {
                path: 'add',
                title: 'Añadir Boleta',
                ab: 'A'
            },
            {
                path: 'search',
                title: 'Buscar Boletas',
                ab: 'B'
            },
            {
                path: 'searchRef',
                title: 'Boletas con Refacciones',
                ab: 'R'
            },
        ]
    },
    {
        path: '/admin/reports',
        title: 'Reportes',
        type: 'sub',
        icontype: 'nc-icon nc-single-copy-04',
        children: [
            {
                path: 'history',
                title: 'General',
                ab: 'G'
            },
        ]
    },
    {
        path: '/admin/inventario',
        title: 'Inventario',
        type: 'sub',
        icontype: 'nc-icon nc-laptop',
        children: [
            {
                path: 'series',
                title: 'Series',
                ab: 'S'
            },
            {
                path: 'modelos',
                title: 'Modelos',
                ab: 'M'
            },
            {
                path: 'marcas',
                title: 'Marcas',
                ab: 'M'
            },
        ]
    },
    {
        path: '/admin/technicals',
        title: 'Técnicos',
        type: 'sub',
        icontype: 'nc-icon nc-badge',
        children: [
            {
                path: 'add',
                title: 'Añadir Técnico',
                ab: 'A'
            },
            {
                path: 'search',
                title: 'Buscar Técnicos',
                ab: 'B'
            },
        ]
    },
    {
        path: '/admin/admins',
        title: 'Admins',
        type: 'sub',
        icontype: 'nc-icon nc-key-25',
        children: [
            {
                path: 'add',
                title: 'Añadir Admin',
                ab: 'A'
            },
            {
                path: 'search',
                title: 'Buscar Admin',
                ab: 'B'
            },
        ]
    },
    {
        path: '/admin/hardware',
        title: 'Refacciones',
        type: 'sub',
        icontype: 'nc-icon nc-settings',
        children: [
            {
                path: 'add',
                title: 'Añadir Refacción',
                ab: 'A'
            },
            {
                path: 'assign',
                title: 'Asignar Refacción',
                ab: 'A'
            },
            {
                path: 'search',
                title: 'Buscar Refacciones',
                ab: 'B'
            },
        ]
    },
    {
        path: '/admin/rastreo',
        title: 'Seguimiento',
        type: 'link',
        icontype: 'nc-icon nc-globe',
    }
];

// Menu Items For The Grocer
export const ROUTES_Grocer: RouteInfo[] = [
    {
        path: '/grocer/profile',
        title: 'Mi Perfil',
        type: 'link',
        icontype: 'nc-icon nc-circle-10'
    },
    {
        path: '/grocer/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'nc-icon nc-bank'
    },
    {
        path: '/grocer/hardware',
        title: 'Refacciones',
        type: 'sub',
        icontype: 'nc-icon nc-settings',
        children: [
            {
                path: 'add',
                title: 'Añadir Refacción',
                ab: 'A'
            },
            {
                path: 'assign',
                title: 'Asignar Refacción',
                ab: 'A'
            },
            {
                path: 'search',
                title: 'Buscar Refacciones',
                ab: 'B'
            },
        ]
    },
    {
        path: '/grocer/rastreo',
        title: 'Seguimiento',
        type: 'link',
        icontype: 'nc-icon nc-globe',
    }
];

// Menu Items For The Supervisor
export const ROUTES_Supervisor: RouteInfo[] = [
    {
        path: '/supervisor/profile',
        title: 'Mi Perfil',
        type: 'link',
        icontype: 'nc-icon nc-circle-10'
    },
    {
        path: '/supervisor/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'nc-icon nc-bank'
    },
    {
        path: '/supervisor/tickets',
        title: 'Boletas',
        type: 'sub',
        icontype: 'nc-icon nc-tag-content',
        children: [
            {
                path: 'add',
                title: 'Añadir Boleta',
                ab: 'A'
            },
            {
                path: 'search',
                title: 'Buscar Boletas',
                ab: 'B'
            },
            // {
            //     path: 'searchRef',
            //     title: 'Boletas con Refacciones',
            //     ab: 'R'
            // },
        ]
    },
    {
        path: '/supervisor/contracts/search',
        title: 'Contratos',
        type: 'link',
        icontype: 'nc-icon nc-paper'
    },
    {
        path: '/supervisor/reports/complete',
        title: 'Reportes',
        type: 'link',
        icontype: 'nc-icon nc-single-copy-04'
    }
];

// Menu Items For The Technical
export const ROUTES_Technical: RouteInfo[] = [
    {
        path: '/technical/profile',
        title: 'Mi Perfil',
        type: 'link',
        icontype: 'nc-icon nc-circle-10'
    },
    {
        path: '/technical/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'nc-icon nc-bank'
    },
    {
        path: '/technical/reports',
        title: 'Reportes',
        type: 'sub',
        icontype: 'nc-icon nc-single-copy-04',
        children: [
            {
                path: 'list',
                title: 'Lista de Reportes',
                ab: 'L'
            },
            {
                path: 'history',
                title: 'Historial de Reportes',
                ab: 'H'
            }
        ]
    },
    {
        path: '/technical/tickets',
        title: 'Boletas',
        type: 'sub',
        icontype: 'nc-icon nc-tag-content',
        children: [
            {
                path: 'add',
                title: 'Crear Boleta',
                ab: 'A'
            },
            {
                path: 'history',
                title: 'Historial de Boletas',
                ab: 'H'
            },
            {
                path: 'bitacorahistory',
                title: 'Bitacora',
                ab: 'B'
            },
        ]
    },
    {
        path: '/technical/hardware',
        title: 'Refacciones',
        type: 'sub',
        icontype: 'nc-icon nc-settings',
        children: [
            {
                path: 'search',
                title: 'Refacciones Asignadas',
                ab: 'R'
            },
        ]
    },

];


@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent {
    public menuItems: any[];
    private href = '';

    constructor(private router: Router, public auth: AuthService) { }

    isNotMobileMenu() {
        if (window.outerWidth > 991) {
            return false;
        }

        return true;
    }

    ngOnInit(): void {
        this.href = this.router.url;

        let admin_flag = this.href.includes('admin');
        let technical_flag = this.href.includes('technical');
        let supervisor_flag = this.href.includes('supervisor');

        if (admin_flag) {
            if (this.auth.getRole() === '3') {
                this.menuItems = ROUTES_SuperAdmin.filter(menuItem => menuItem);
            } else {
                this.menuItems = ROUTES_Admin.filter(menuItem => menuItem);
            }
        } else if (technical_flag) {
            this.menuItems = ROUTES_Technical.filter(menuItem => menuItem);
        } else if (supervisor_flag) {
            this.menuItems = ROUTES_Supervisor.filter(menuItem => menuItem);
        } else {
            this.menuItems = ROUTES_Grocer.filter(menuItem => menuItem);
        }
    }

    ngAfterViewInit() {
    }

    logOut() {
        sessionStorage.clear();
        this.router.navigate(['']);
    }
}

import { Injectable } from '@angular/core';
import { constants } from "../../config/constants";
import { HttpClient, HttpHeaders } from "@angular/common/http";


@Injectable()
export class DataService {

    private static httpOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        })
    };

    private static http: HttpClient;

    constructor(public constants: constants) {
        this.setTicketDeleteFlag('false');
    }

    public setTicketIdEdit(id) {
        sessionStorage.setItem('id-ticket-edit', id);
    }

    public getTicketIdEdit() {
        return sessionStorage.getItem('id-ticket-edit');
    }

    public setTicketTypeEdit(type) {
        sessionStorage.setItem('id-ticket-type', type);
    }

    public getTicketTypeEdit() {
        return sessionStorage.getItem('id-ticket-type');
    }

    public setTicketDeleteFlag(flag) {
        sessionStorage.setItem('del-ticket-flag', flag);
    }

    public getTicketDeleteFlag() {
        return sessionStorage.getItem('del-ticket-flag');
    }

    public setTechniclaWarningPDF(data) {
        sessionStorage.setItem('data-technical-ticket', data);
    }

    public getTechnicalWarningPDF() {
        return sessionStorage.getItem('data-technical-ticket');
    }

    public setTechnicalIdTiecketPDF(id) {
        sessionStorage.setItem('id-technical-ticket-edit', id);
    }

    public getTechnicalIdTicketPDF() {
        return sessionStorage.getItem('id-technical-ticket-edit');
    }

    public setTechnicalEmailPDF(email) {
        sessionStorage.setItem('email-technical-ticket-edit', email);
    }

    public getTechnialEmailPDF() {
        return sessionStorage.getItem('email-technical-ticket-edit');
    }

    public setAdminTicketSearchEdit(boleta) {
        sessionStorage.setItem('search-edit-admin-ticket', boleta);
    }

    public getAdminTicketSearchEdit() {
        return sessionStorage.getItem('search-edit-admin-ticket');
    }

    public setTechnicalTicketSearchView(boleta) {
        sessionStorage.setItem('search-view-technical-ticket', boleta);
    }

    public getTechnicalTicketSearchView() {
        return sessionStorage.getItem('search-view-technical-ticket');
    }

    public setSourceToPDFView(source) {
        sessionStorage.setItem('pdf-source-view', source);
    }

    public getSourceToPDFView() {
        return sessionStorage.getItem('pdf-source-view');
    }

    public setTechnicalTicketsViewRestData(data) {
        sessionStorage.setItem('search-view-technical-rest', data);
    }

    public getTechnicalTicketsViewRestData() {
        return sessionStorage.getItem('search-view-technical-rest');
    }

    public setTicketsRefactions(data) {
        sessionStorage.setItem('tickets-refactions', data);
    }

    public getTicketsRefaction() {
        return sessionStorage.getItem('tickets-refactions');
    }

    public setTicketSign(sign) {
        sessionStorage.setItem('tickets-sign', sign);
    }

    public getTicketSign() {
        return sessionStorage.getItem('tickets-sign');
    }

    public setReportView(report) {
        sessionStorage.setItem('report-view', report);
    }

    public getReportView() {
        return sessionStorage.getItem('report-view');
    }

    public setFlagReportView(flag) {
        sessionStorage.setItem('report-flag', flag);
    }

    public getFlagReportView() {
        return sessionStorage.getItem('report-flag');
    }

    public getTicketNotes() {
        return sessionStorage.getItem('ticket-notes-id');
    }

    public setTicketNotes(data) {
        sessionStorage.setItem('ticket-notes-id', data);
    }
}
import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {
    constructor() { }

    public isAuthenticated(): boolean {
        return sessionStorage.getItem('_id') != null;
    }

    public setToken(user) {
        sessionStorage.setItem('_id', user);
    }

    public setRole(role) {
        sessionStorage.setItem('role', role);
    }

    public getRole() {
        return sessionStorage.getItem('role');
    }

    public setUser(data) {
        sessionStorage.setItem('UserInfo', data);
    }

    public getToken() {
        return sessionStorage.getItem('_id');
    }

    public getUserInfo() {
        return sessionStorage.getItem('UserInfo');
    }
}
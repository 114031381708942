import { Injectable } from '@angular/core';
import { sha256 } from 'js-sha256';

@Injectable()
export class EncriptionService {

    constructor() { }

    Encript(item){
        return btoa(item);
    }

    DeEncript(item){
        return atob(item);
    }

    Encript_SHA256(item){
        return sha256(item);
    }

}
import { AuthService} from "../auth.service";
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AdminGuardService implements CanActivate {


    constructor(private _authService: AuthService, private _router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (this._authService.isAuthenticated() && (this._authService.getRole() == '1' || this._authService.getRole() == '3')) {
            return true;
        }
        this._router.navigate(['/404']);
        return true;

    }

}
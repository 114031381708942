import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { AuthService } from '../services/auth/auth.service';
import { EncriptionService } from '../services/encription/encription.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { constants } from '../config/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'app/shared/models/user.model';

declare var $: any;

@Component({
    moduleId: module.id,
    selector: 'login-cmp',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    providers: [NgbModal]
})

export class LoginComponent implements OnInit, OnDestroy {

    focus;
    focus1;

    test: Date = new Date();
    private toggleButton;
    private sidebarVisible: boolean;
    public nativeElement: Node;
    public login: User;
    public loading = false;

    private httpOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        })
    };

    constructor(
        public router: Router,
        public auth: AuthService,
        public encription: EncriptionService,
        private http: HttpClient,
        public constants: constants,
        private element: ElementRef
    ) {
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    static checkFullPageBackgroundImage() {
        let $page = $('.full-page');
        let image_src = $page.data('image');

        if (image_src !== undefined) {
            const image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>';
            $page.append(image_container);
        }
    };

    ngOnInit() {
        sessionStorage.clear();

        this.login = {
            id: '',
            password: ''
        };

        LoginComponent.checkFullPageBackgroundImage();
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];

        setTimeout(function () {
            // after 1000 ms we add the class animated to the login/register card
            $('.card').removeClass('card-hidden');
        }, 700)
    }

    ngOnDestroy() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');
    }

    sidebarToggle() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible == false) {
            setTimeout(function () {
                toggleButton.classList.add('toggled');
            }, 500);
            body.classList.add('nav-open');
            this.sidebarVisible = true;
        } else {
            this.toggleButton.classList.remove('toggled');
            this.sidebarVisible = false;
            body.classList.remove('nav-open');
        }
    }

    showSwal() {
        swal({
            title: '¡Error!',
            text: 'Error de comunicación, inténtelo más tarde.',
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-suplidora',
            type: 'error'
        }).catch(swal.noop)
    }

    showSwal1() {
        swal({
            title: '¡Error!',
            text: 'Usuario o contraseña incorrectos',
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-suplidora',
            type: 'error'
        }).catch(swal.noop)
    }

    Login(model: User) {
        this.loading = true;
        const temp = this.encription.Encript_SHA256(model.password);
        this.auth.setToken(model.id);

        const data = {
            'user': model.id,
            'password': temp
        };

        this.http
            .post<any>(constants.url + 'MSV1/auth', data, this.httpOptions)
            .subscribe(data => {
                if (data.userFound == null) {
                    this.loading = false;
                    this.showSwal();
                } else if (data.userFound == 0) {
                    this.loading = false;
                    this.showSwal1();
                } else if (data.idRol == 1 || data.idRol == 3) {
                    this.auth.setUser(JSON.stringify(data));
                    this.auth.setRole(data.idRol.toString());
                    this.router.navigate(['/admin/dashboard']);
                } else if (data.idRol == 2) {
                    this.auth.setUser(JSON.stringify(data));
                    this.auth.setRole(data.idRol.toString());
                    this.router.navigate(['/technical/dashboard']);
                } else if (data.idRol == 4) {
                    this.auth.setUser(JSON.stringify(data));
                    this.auth.setRole(data.idRol.toString());
                    this.router.navigate(['/grocer/dashboard']);
                } else if (data.idRol == 5) {
                    this.auth.setUser(JSON.stringify(data));
                    this.auth.setRole(data.idRol.toString());
                    this.router.navigate(['/supervisor/dashboard']);
                }
            }, error => {
                this.loading = false;
                this.showSwal();
            });
    }
}
